import { useGSAP } from "@gsap/react";
import { gsap } from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./FeatureSec.module.css";
const FeatureSec = () => {
  const container = useRef();
  const box1 = useRef();
  const h1 = useRef();
  const p1 = useRef();
  const b1 = useRef();
  const t1 = useRef();
  const a1 = useRef();
  const box2 = useRef();
  const h2 = useRef();
  const p2 = useRef();
  const b2 = useRef();
  const t2 = useRef();
  const a2 = useRef();
  const box3 = useRef();
  const h3 = useRef();
  const p3 = useRef();
  const b3 = useRef();
  const a3 = useRef();
  const t3 = useRef();

  gsap.registerPlugin(ScrollTrigger);

  useGSAP(
    () => {
      let ctx = gsap.context(() => {
        gsap.from(h1.current, {
          duration: 1,
          opacity: 0,
          delay: 0.1,
          y: 60,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box1.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(p1.current, {
          duration: 1,
          opacity: 0,
          delay: 0.3,
          y: 70,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box1.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(b1.current, {
          duration: 1,
          opacity: 0,
          delay: 0.4,
          y: 90,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box1.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(a1.current, {
          duration: 1,
          opacity: 0,
          delay: 0.5,
          y: 90,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box1.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(t1.current, {
          duration: 1,
          opacity: 0,
          delay: 0.6,
          x: 90,
          stagger: 0.25,
          scrollTrigger: {
            trigger: box1.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(h2.current, {
          duration: 1,
          opacity: 0,
          delay: 0.1,
          y: 60,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box2.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(p2.current, {
          duration: 1,
          opacity: 0,
          delay: 0.3,
          y: 70,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box2.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(b2.current, {
          duration: 1,
          opacity: 0,
          delay: 0.4,
          y: 90,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box2.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(a2.current, {
          duration: 1,
          opacity: 0,
          delay: 0.5,
          y: 90,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box2.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(t2.current, {
          duration: 1,
          opacity: 0,
          delay: 0.6,
          x: 90,
          stagger: 0.25,
          scrollTrigger: {
            trigger: box2.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(h3.current, {
          duration: 1,
          opacity: 0,
          delay: 0.1,
          y: 60,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box3.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(p3.current, {
          duration: 1,
          opacity: 0,
          delay: 0.3,
          y: 70,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box3.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(b3.current, {
          duration: 1,
          opacity: 0,
          delay: 0.4,
          y: 90,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box3.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(a3.current, {
          duration: 1,
          opacity: 0,
          delay: 0.5,
          y: 90,
          stagger: 0.15,
          scrollTrigger: {
            trigger: box3.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
        gsap.from(t3.current, {
          duration: 1,
          opacity: 0,
          delay: 0.6,
          x: 90,
          stagger: 0.25,
          scrollTrigger: {
            trigger: box3.current,
            start: "top bottom+=50",
            end: "+=100",
            toggleActions: "play none none none",
          },
        });
      });

      return () => ctx.revert();
    },
    { scope: container }
  );
  return (
    <section className={styles["hm-features"]} ref={container}>
      <div className="container">
        <div className={styles.featuresGrid}>
          <div className={styles.featuresCol} ref={box1}>
            <div className={styles.featuresBox}>
              <div className={styles.featuresContent}>
                <div className={styles.featuresHeading}>
                  <div className={styles.featuresCount}>
                    <span>01</span>
                  </div>
                  <div className={styles.featureLogo} ref={h1}>
                    <img src="assets/images/sa-vidsell.png" alt="vidsell" />
                  </div>
                </div>

                {/* <h3 ref={h1}>VidSell</h3> */}

                <p ref={p1}>
                  Deliver Store like personalized & engaging shopping
                  experience, ONLINE!
                </p>
                <ul ref={b1}>
                  <li>Live Video Selling</li>
                  <li>Schedule Video Selling</li>
                  <li>Outbound Video Selling</li>
                </ul>
                <div ref={a1}>
                  <Link
                    to={"/vidsell"}
                    relative="path"
                    className="btn btn-primary btn-typ-1"
                  >
                    <div>
                      <span>Explore the Feature</span>
                      <span>Explore the Feature</span>
                    </div>
                  </Link>
                  {/* <button className="btn btn-primary btn-typ-1"><div><span>Explore the Feature</span><span>Explore the Feature</span></div></button> */}
                </div>
              </div>
              <div className={styles.featuresThumb} ref={t1}>
                <div className={styles.thumbBox}>
                  <img
                    src="assets/images/liveShopping.png"
                    alt="live-shopping"
                    className="imgResponsive"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.featuresCol} ${styles.featureBg1}`}
            ref={box2}
          >
            <div className={styles.featuresBox}>
              <div className={styles.featuresContent}>
                <div className={styles.featuresHeading}>
                  <div className={styles.featuresCount}>
                    <span>02</span>
                  </div>
                  {/* <h3 ref={h2}>Reels</h3> */}
                  <div className={styles.featureLogo} ref={h2}>
                    <img src="assets/images/sa-reels.png" alt="reels" />
                  </div>
                </div>
                <p ref={p2}>
                  Turn viewers into buyers with Intractive and Engaging Videos!
                </p>
                <ul ref={b2}>
                  <li>Immersive Experience</li>
                  <li>7 Awesome Styles</li>
                  <li>Fast Loading</li>
                </ul>
                <div ref={a2}>
                  <Link
                    to={"/reels"}
                    relative="path"
                    className="btn btn-primary btn-typ-1"
                  >
                    <div>
                      <span>Explore the Feature</span>
                      <span>Explore the Feature</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles.featuresThumb} ref={t2}>
                <div className={styles.thumbBox}>
                  <img
                    src="assets/images/shoppableVideo.png"
                    alt="live-stream"
                    className="imgResponsive"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.featuresCol} ref={box3}>
            <div className={styles.featuresBox}>
              <div className={styles.featuresContent}>
                <div className={styles.featuresHeading}>
                  <div className={styles.featuresCount}>
                    <span>03</span>
                  </div>
                  {/* <h3 ref={h3}>Strike</h3> */}
                  <div className={styles.featureLogo} ref={h3}>
                    <img src="assets/images/sa-strike.png" alt="strike" />
                  </div>
                </div>
                <p ref={p3}>
                  Capture the moment, strike a conversion & convert like pro
                </p>
                <ul ref={b3}>
                  <li>Live visitor Tracking</li>
                  <li>6 Filtering Mechanism</li>
                  <li>Live Agent & Auto Mode</li>
                </ul>
                <div ref={a3}>
                  <Link
                    to={"/strike"}
                    relative="path"
                    className="btn btn-primary btn-typ-1"
                  >
                    <div>
                      <span>Explore the Feature</span>
                      <span>Explore the Feature</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles.featuresThumb} ref={t3}>
                <div className={styles.thumbBox}>
                  <img
                    src="assets/images/auto-engage-new.jpg"
                    alt="shoppable-video"
                    className="imgResponsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureSec;
